export const packs = {
    support: {
        text1: {
            content: "Bénéficiez d'une {0} afin de vous guider dans les fonctions avancées d'Osteo2ls.",
            strong: "aide technique personnalisée"
        },
        text2: {
            content: "Ce pack est valable pendant 12 mois, {0}. Le renouvellement n'est pas automatique.",
            strong: "sans engagement"
        }
    },
    unlimited: {
        text1: {
            content: "Passez à la vitesse supérieure en débloquant {0}. ",
            strong: "votre nombre de patients en illimité"
        },
        text2: "Permettez à vos patients de prendre rendez-vous avec vous en ligne, via une page qui vous est dédiée. Soyez rassurés : notre équipe vous aide à tout moment grâce à l'onglet Support dans l'application.",
        text3: "Le Paragraphe qui explique le 01 octobre 2024"
    },
    subscription: {
        text1: {
            content: "Profitez d'un pack tranquillité, en {0}.",
            strong: "échelonnant vos paiements sur 12 mois"
        },
        text2: "Vous bénéficiez d'un nombre illimité de patients, ainsi que d'une année de maintenance offerte."
    },
    planning: {
        text1: "Offrez à vos patients la possibilité de prendre leur rendez-vous en ligne.",
        text2: "Restez concentré sur votre métier pendant vos consultations et gagnez du temps.",
        text3: {
            content: "Ce pack inclut également {0}.",
            span: "(100 SMS / mois ou 1200 SMS / an)"
        },
        'link-title': "Plus d'informations sur le planning en ligne",
        'link-label': "Plus d'informations…"
    },
    card: {
        text1: "Permettez à vos patients de retrouver vos coordonnées rapidement.",
        text2: "Indiquez leur facilement la date et l'heure du prochain rendez-vous."
    },
    sms: {
        text1: {
            content: "Diminuez vos rendez-vous manqués en envoyant un {0}.",
            strong: "rappel par SMS"
        },
        text2: {
            content: "Communiquez plus efficacement avec vos patients grâce aux {0}.",
            'link-title': "Créez votre campagne SMS",
            'link-label': "campagnes SMS"
        },
        text3: {
            content: "Pack utilisable sans limite de temps, et {0}.",
            strong: "sans engagement"
        }
    },
    import: {
		text1: {
            content: "Vous utilisez un autre logiciel et vous souhaitez {0} vers Osteo2ls ?",
            strong: "migrer vos données"
        },
        text2: "C'est possible, après étude et réinterprétation technique de votre base de données."
    },
    site: {
		text1: {
            content: "Osteo2ls vous permet de créer votre site internet avec un partenaire de confiance : {0}.",
            strong: "Simplébo"
        },
        text2: {
            content: "Nous avons négocié pour nos membres des {0} et {1}.",
            strong1: "frais de création à -50%",
            strong2: " 10 % de remise sur les abonnements Simplébo"
        }
    }
}
