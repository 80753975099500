import Render from '@Views/root/patients/main.html'
import { Component, Vue } from 'vue-property-decorator'
import { ASidePatients } from './aside-patients'
import { ContextMain } from './context-main'
import { PatientItem } from './patient-item'
import { IUser, INotification, INotificationAction, IPatient, IPatientHistoric, IBreadcrumbItem } from '@Store/types'
import { HistoricService } from '@Services/index'
import { Patient, Backup } from '@Models/index'
import {Profile, PackType} from '@Enums/index'
import { breadcrumb, configuration, notif, user } from '@Store/modules'
import {IDropdownActionItem} from "@ViewModels/components";

@Render
@Component({
	components: {
		'context-main': ContextMain,
		'aside-patients': ASidePatients,
		'patient-item': PatientItem
	}
})
export class Main extends Vue {

	config: any
	loadingCounts: boolean = false
	loadingLastReadPatientList: boolean = false
	loadingNextPatientList: boolean = false
	lastReadPatientList: IPatientHistoric[] = []
	nextPatientList: IPatientHistoric[] = []
	counts: {count_total: number, count_blacklist: number, count_current_month: number} = {count_total: 0, count_blacklist: 0, count_current_month: 0}
	private service: HistoricService = null

	//#region Getters
	get isAnonymous(): boolean {
		return configuration.isAnonymous
	}
	get countTotal(): number {
		return this.counts.count_total
	}
	get countBlacklist(): number {
		return this.counts.count_blacklist
	}
	get countCurrentMonth(): number {
		return this.counts.count_current_month
	}
	get displayWarning(): boolean {
		if (this.user.isSubscriptionRequired) {
			return this.countTotal > 25;
		}
		return this.user.profile === Profile.Main && this.user.license !== -1 && this.countTotal >= (this.user.license - 10)
	}
	get to(): {name: string, params: {pack: string}} {
        return {name: 'purchase-pack', params: {pack: PackType.Unlimited.toString()}}
	}
	get canCreateNewPatient(): boolean {
		if (this.user.isSubscriptionRequired) {
			return this.countTotal < 25;
		}
		return this.user.profile !== Profile.Main || this.user.license === -1 ? true : this.user.license > this.countTotal
	}
	get actionItems(): IDropdownActionItem[] {
		return [
			{
				value: 1,
				action: this.goToCampaign,
				title: this.$i18n.t('vm.root.patients.main.sms-campaign.title').toString(),
				label: this.$i18n.t('vm.root.patients.main.sms-campaign.action').toString(),
				icon: "comment-alt-lines"
			},
			{
				value: 2,
				action: this.goToMerge,
				title: this.$i18n.t('vm.root.patients.main.merge.title').toString(),
				label: this.$i18n.t('vm.root.patients.main.merge.action').toString(),
				icon: "code-merge"
			},
		]
	}

	private get user(): IUser {
		return user.user
	}
	//#endregion

	goToCampaign() {
		this.$router.push({ name: 'patients-campaigns' })
	}
	goToMerge() {
		this.$router.push({ name: 'patients-merge' })
	}
	mounted() {
		breadcrumb.updateLinked(true)
		this.initializeBreadcrumb()
	}

	createNewPatient(): void {
		if (this.canCreateNewPatient) {
			this.$router.push({name: 'patient-coordinates'})
		} else {
			let purchaseAction: INotificationAction = {
				label: this.$i18n.t('vm.root.patients.main.purchase.label').toString(),
				callback: () => { this.$router.push(this.to) }
			}
			let notification: INotification = {
				message: this.$i18n.t('vm.root.patients.main.purchase.message').toString(),
				actions: [purchaseAction],
				canClose: true,
				delay: false
			}
			notif.error(notification)
		}
	}

	private initializeBreadcrumb(): void {
		let item: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString() }
		breadcrumb.updateItems([item])

		this.service = new HistoricService()
		this.initializeCounts()
		this.initializeLastRead()
		this.initializeNextPatient()
	}

	private initializeCounts(): void {
		this.loadingCounts = true
		this.service.getCounts()
		.then(result => {
			this.counts = result
			this.loadingCounts = false
		})
		.catch(() => {
			this.counts = {count_total: 0, count_blacklist: 0, count_current_month: 0}
			this.loadingCounts = false
		})
	}

	private initializeLastRead(): void {
		this.loadingLastReadPatientList = true
		this.service.getLastReadPatient()
		.then(result => {
			this.lastReadPatientList = result.sort((a: IPatientHistoric, b:IPatientHistoric) => b.time.getTime() - a.time.getTime())
			this.loadingLastReadPatientList = false
		})
		.catch(() => {
			let patients: IPatient[] = Backup.getInstance().patientList
			let list:IPatient[] = patients.slice(0, Math.min(6, patients.length))
			if (list.length === 0) {
				// keep old values
			} else {
				this.lastReadPatientList = list.map(patient => { return Patient.toPatientHistoric(patient) }).sort((a: IPatientHistoric, b:IPatientHistoric) => b.time.getTime() - a.time.getTime())
			}
			this.loadingLastReadPatientList = false
		})
	}

	private initializeNextPatient(): void {
		this.loadingNextPatientList = true
		this.service.getNextPatient()
		.then(result => {
			this.nextPatientList = result
			this.loadingNextPatientList = false
		})
		.catch(() => {
			// keep old values
			this.loadingNextPatientList = false
		})
	}
}
