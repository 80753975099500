import Render from '@Views/root/shop/pack-item.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { INotification, IPack } from '@Store/types'
import { PackType } from '@Enums/index'
import { notif, user } from '@Store/modules'

@Render
@Component({})
export class PackItem extends Vue {

  @Prop() pack: IPack

  get defaultPrice(): number {
    if (this.pack.recurrent) {
      this.pack.values.sort((a, b) => (a.price / Math.abs(a.value) > b.price / Math.abs(b.value)) ? 1 : -1)
      return this.pack.values[0].price / Math.abs(this.pack.values[0].value)
    } else if (this.pack.values.length > 1) {
      this.pack.values.sort((a, b) => (a.price > b.price) ? 1 : -1)
      return this.pack.values[0].price
    } else {
      return this.pack.values[0].price
    }
  }
  get manyPrice(): boolean {
    return this.pack.values.length > 1
  }
  get isSupport(): boolean {
		return this.pack.id === PackType.Support
  }
  get isUnlimited(): boolean {
		return this.pack.id === PackType.Unlimited
  }
  get isSubscription(): boolean {
		return this.pack.id === PackType.Subscription
  }
  get isPlanning(): boolean {
		return this.pack.id === PackType.Planning
  }
  get isCard(): boolean {
		return this.pack.id === PackType.Card
  }
  get isSms(): boolean {
		return this.pack.id === PackType.Sms
  }
  get isImport(): boolean {
		return this.pack.id === PackType.Import
  }
  get isSite(): boolean {
		return this.pack.id === PackType.Site
  }

  goTo(type: PackType): void {
    if (!this.isAlreadyPayed(type)) {
      this.$router.push({name: 'purchase-pack', params:{pack: type.toString()}})
    } else {
      let message: string = this.$i18n.t('vm.root.shop.pack-item.already-use').toString()
      let notification: INotification = { message: message, actions: [], delay: 5000, canClose: true }
      notif.warning(notification)
    }
  }

  private isAlreadyPayed(type: PackType): boolean {
	  return !user.currentUser.isSubscriptionRequired && (type === PackType.Unlimited || type === PackType.Subscription);
  }
}
